import React from 'react';
import { useEffect } from 'react';

import WorksListItem from './WorksListItem';

type Props = {
  items: any;
};

const WorksList: React.FunctionComponent<Props> = ({ items }) => {
  console.log('ge')
  console.log(items);
  return (
    <div className="boxes">
      {items.allWordpressWpWorks.edges
        .filter(item => !Array.isArray(item.node.acf?.works_hide) || !item.node.acf?.works_hide.includes("1")) 
        .map(item => (
          <div className="box" key={item.node.id} id={item.node.acf?.hide}>
            <WorksListItem item={item} />
          </div>
        ))}
    </div>
  );
};

export default WorksList;
